<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
             <button class="btn-search ml-5" type="button" @click="setTableData()">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
      <!-- <div class="box-ui-select searchbar-content">
        <div class="title">
          <i class="fas fa-cog"></i>
          <span>{{ $t('table.head.id') }}</span> :
        </div>
        <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="'아이디'" />
        <button class="btn-search" type="button" @click="pageSeach">
          <i class="fas fa-search"></i>
        </button>
      </div> -->
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <tr v-for="(item, idx) in applyList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('company', item.regId)">{{ item.regId }}</button>
              </td>
              <td>
                 <template v-if="item.calculateIdx">
                    <div class="roboto">{{ item.calculateIdx }}</div>
                 </template>
                 <template v-else> - </template>
              </td>
              <td>
                <span class="money-apply roboto">{{ numberWithCommas(item.preCreditAmt) }}</span>
              </td>
              <td>
                <span class="money-apply roboto">{{ numberWithCommas(item.creditAmt) }}</span>
              </td>
               <td>
                <span v-if="item.creditStatus==='1'" class="money-apply roboto">{{ numberWithCommas(item.realCreditAmt) }}</span>
                <span v-if="item.creditStatus!=='1'" class="money-apply">-</span>
              </td>
              <td>
                <span class="money-apply roboto" v-if="item.creditStatus==='1'">{{ numberWithCommas(Number(item.realCreditAmt) + Number(item.preCreditAmt)) }}</span>
                <span class="money-apply roboto" v-if="item.creditStatus==='-1'">{{ numberWithCommas(Number(item.creditAmt) + Number(item.preCreditAmt)) }}</span>
              </td>
              <td class="roboto">{{ Number(item.creditRate).toFixed(2) }}%</td>
              <td>
                <span v-if="item.creditStatus==='1'" class="money-apply roboto">{{ numberWithCommas(Math.abs(Number(item.realCreditAmt)*(Number(item.creditRate)*0.01)).toFixed(0)) }}</span>
                <span v-if="item.creditStatus!=='1'" class="money-apply roboto">{{ numberWithCommas(Math.abs(Number(item.creditAmt)*(Number(item.creditRate)*0.01)).toFixed(0)) }}</span>
              </td>
              <td style="width: 15%" class="roboto">
                {{ item.regDate }}
              </td>
              <td style="width: 15%">
                <span v-if="Number(item.creditStatus) > 0" class="roboto">{{ item.updDate }}</span>
                <span v-else>-</span>
              </td>
              <td>
                <div class="fx-center-all">
                  <span class="emptybet">{{ item.statusText }}</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="11">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import { getSiteData } from '@/libs/auth-helper'
import { creditUpdateStatus } from '@/api/member.js'
import { creditMyList } from '@/api/company.js'
import { getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import UiCheck from '@/components/ui/UiCheckSet'
import Memo from '@/components/common/memo'

export default {
  name: 'CreditExchange',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    UiCheck,
    Memo
  },
  props: {
    status: {
      type: String,
      default: '0'
    }
  },
  watch: {
    $route: {
      async handler () {
        const status = this.$route.params.status
        console.log(status)
        this.setTableData(1, status)
      }
    }
  },
  data: function () {
    return {
      tableName: 'Basic',
      headInfo: {
        fstColumn: false,
        dataList: ['companyId', 'onecode', 'prePT', 'applyCredit', 'realExchangePT', 'postPT', 'creditRate', 'exAmt', 'applyDate', 'updDate', 'status']
      },
      reqData: {
        startDate: '',
        endDate: '',
        creditType: '-1',
        creditStatus: '',
        page: 1,
        count_per_list: '30',
        adminId: ''
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      pageInfo: {},
      applyList: [],
      siteInfo: {},
      checkList: [
        {
          val: '0',
          text: '요청'
        },
        {
          val: '1',
          text: '대기'
        },
        {
          val: '2',
          text: '완료'
        }
      ]
    }
  },
  methods: {
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    openWindow () {
      window.open('/user/userDetail', 'userDetail', 'top=0,left=150,scrollbars=no,resizable=no,width=1900,height=885,status=no')
    },
    async getCreditApplyList (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const data = this.reqData
      return await creditMyList(data)
    },
    async changeListType (evt) {
      const target = evt.target
      const chooseType = target.value
      this.reqData.creditStatus = chooseType
      this.setTableData(1)
    },
    async setTableData (pageNum, status) {
      if (status) {
        this.reqData.creditStatus = ''
      }
      this.reqData.adminId = this.siteInfo.siteId
      this.reqData.creditType = '-1'
      const res = await this.getCreditApplyList(pageNum)
      const applyList = res.data.list
      const pageInfo = res.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      this.applyList = this.dataConvertor(applyList)
      console.log(this.applyList)
    },
    creditPayment (dataObj) {
      const _creditAmt = prompt('알 지급 금액', dataObj.creditAmt)
      const trigger = {
        msg: '',
        prog: false
      }
      if (isNaN(_creditAmt)) {
        trigger.msg = '숫자만 입력해주세요.'
      } else if (_creditAmt <= 0) {
        trigger.msg = '지급 최소 금액은 0 이상 입니다. 다시 입력해주세요.'
      } else if (_creditAmt == null || _creditAmt == undefined) {
        trigger.msg = ''
      } else {
        trigger.msg = _creditAmt
        trigger.prog = confirm(`${_creditAmt}을 지급합니다.`)
      }

      return trigger
    },
    async statusChange (idx, creditAmt, type, status) {
      const reqData = {
        creditIdx: idx,
        creditType: type,
        creditStatus: status,
        creditAmt
      }
      switch (status) {
        case '0':
          reqData.creditStatus = '1'
          break
      }
      const res = await creditUpdateStatus(reqData)
      this.setTableData()
      if (res.resultCode === '0') {
        if (reqData.creditStatus === '1') {
          alert('알 환전 완료')
        } else {
          alert('알 상태 변경 완료')
        }
      } else {
        if (reqData.creditStatus === '1') {
          alert('알 환전에 실패 했습니다. 다시 시도해주세요.')
        } else {
          alert('알 상태 변경에 실패 했습니다. 다시 시도해주세요.')
        }
      }
    },
    dataConvertor (dataList) {
      const _dataList = dataList
      if (!dataList.length || dataList.length === 0) {
        return _dataList
      }
      _dataList.forEach(items => {
        const creditStatus = items.creditStatus
        let statusText = ''
        // - 0:요청, 1:완료, 2:대기 , -1"거부
        if (creditStatus === '0') {
          statusText = '대기중'
          items.statusClass = 'apply'
        } else if (creditStatus === '1') {
          statusText = '환전완료'
          items.statusClass = 'approve'
        }
        if (!items.realCreditAmt) {
          items.realCreditAmt = '-'
        }
        items.statusText = statusText
        items.regDate = items.regDate.replace('T', ' ')
        items.updDate = items.updDate.replace('T', ' ')
        for (const item in items) {
          if (items[item] === '') {
            items[item] = '-'
          }
        }
      })
      return _dataList
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    this.reqData.adminId = this.siteInfo.siteId
    this.setStartDate()
    this.setEndDate()
    this.setTableData()
  }
}
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
</style>
